export const adjMascFR: Array<string> = [
    'Absents',
    'Absolus',
    'Accueilants',
    'Actifs',
    'Adjacents',
    'Administratifs',
    'Affreux',
    'Alternatifs',
    'Amateurs',
    'Ambitieux',
    'Amicaux',
    'Amusants',
    'Anciens',
    'Angoissants',
    'Annuels',
    'Anxieux',
    'Apparents',
    'Artificiels',
    'Astucieux',
    'Attendus',
    'Attirants',
    'Attractifs',
    'Authentiques',
    'Automatiques',
    'Beaux',
    'Bienvenus',
    'Bizarres',
    'Blancs',
    'Bleus',
    'Blonds',
    'Bons',
    'Brefs',
    'Brillants',
    'Bruns',
    'Bruts',
    'Bruyants',
    'Centraux',
    'Certains',
    'Chaleureux',
    'Chanceux',
    'Charmants',
    'Chauds',
    'Chers',
    'Chirurgicaux',
    'Choquants',
    'Civils',
    'Cognitifs',
    'Collants',
    'Collectifs',
    'Commerciaux',
    'Communs',
    'Complets',
    'Confortables',
    'Confus',
    'Conscients',
    'Conservateurs',
    'Constants',
    'Constitutionnels',
    'Contemporains',
    'Continus',
    'Convaincants',
    'Convaincus',
    'Conventionnels',
    'Corrects',
    'Correspondants',
    'Courageux',
    'Courants',
    'Couverts',
    'Creux',
    'Cruciaux',
    'Cruels',
    'Culturels',
    'Curieux',
    'Dangereux',
    'Derniers',
    'Destructeurs',
    'Directs',
    'Disparus',
    'Distinctifs',
    'Distincts',
    'Divertissants',
    'Divins',
    'Dominants',
    'Douloureux',
    'Doux',
    'Droits',
    'Durs',
    'Effrayants',
    'Encourageants',
    'Endommageants',
    'Endormis',
    'Engageants',
    'Ennuyeux',
    'Entiers',
    'Exacts',
    'Excellents',
    'Exceptionnels',
    'Excessifs',
    'Exclusifs',
    'Experts',
    'Explosifs',
    'Extensifs',
    'Fabuleux',
    'Familiers',
    'Fascinants',
    'Fataux',
    'Faux',
    'Favoris',
    'Fiers',
    'Financiers',
    'Finaux',
    'Fonctionnels',
    'Fondamentaux',
    'Formels',
    'Forts',
    'Fous',
    'Frais',
    'Frappants',
    'Froids',
    'Frustrants',
    'Furieux',
    'Futurs',
    'Globaux',
    'Glorieux',
    'Grands',
    'Gris',
    'Grossiers',
    'Habituels',
    'Hauts',
    'Heureux',
    'Hilarants',
    'Honteux',
    'Idiots',
    'Imminents',
    'Impairs',
    'Impatients',
    'Importants',
    'Impressionnants',
    'Inattendus',
    'Inconnus',
    'Incorrects',
    'Indirects',
    'Individuels',
    'Industriels',
    'Influents',
    'Informels',
    'Inhabituels',
    'Initiaux',
    'Innocents',
    'Innovants',
    'Institutionnels',
    'Instrumentaux',
    'Insuffisants',
    'Intacts',
    'Intellectuels',
    'Intelligents',
    'Intensifs',
    'Interactifs',
    'Internationaux',
    'Intrigants',
    'Jolis',
    'Joyeux',
    'Lents',
    'Locaux',
    'Lointains',
    'Lourds',
    'Luxueux',
    'Majeurs',
    'Malheureux',
    'Marginaux',
    'Marins',
    'Martiaux',
    'Massifs',
    'Mauvais',
    'Meilleurs',
    'Mensuels',
    'Merveilleux',
    'Mignons',
    'Minimaux',
    'Moraux',
    'Municipaux',
    'Musicaux',
    'Mutuels',
    'Nationaux',
    'Naturels',
    'Navaux',
    'Nerveux',
    'Nocifs',
    'Nombreux',
    'Normaux',
    'Nouveaux',
    'Objectifs',
    'Occasionnels',
    'Occidentaux',
    'Offensifs',
    'Officiels',
    'Opportuns',
    'Originaux',
    'Ouverts',
    'Parentaux',
    'Paresseux',
    'Parfaits',
    'Particuliers',
    'Partiels',
    'Passifs',
    'Passionnants',
    'Patients',
    'Perdus',
    'Permanents',
    'Persistants',
    'Personnels',
    'Pertinents',
    'Petits',
    'Pliants',
    'Pointus',
    'Polis',
    'Positifs',
    'Premiers',
    'Prestigieux',
    'Principaux',
    'Productifs',
    'Professionnels',
    'Profonds',
    'Progressifs',
    'Prometteurs',
    'Protecteurs',
    'Provinciaux',
    'Prudents',
    'Puissants',
    'Purs',
    'Radicaux',
    'Rationnels',
    'Ravis',
    'Reconnaissants',
    'Relatifs',
    'Relaxants',
    'Religieux',
    'Respectifs',
    'Rivaux',
    'Royaux',
    'Rugueux',
    'Ruraux',
    'Saints',
    'Satisfaits',
    'Secrets',
    'Seuls',
    'Significatifs',
    'Silencieux',
    'Sociaux',
    'Spirituels',
    'Sportifs',
    'Stricts',
    'Substantiels',
    'Subtils',
    'Successifs',
    'Suffisants',
    'Surprenants',
    'Surpris',
    'Talentueux',
    'Terrifiants',
    'Traditionnels',
    'Tranchants',
    'Transparents',
    'Tribaux',
    'Trompeurs',
    'Tropicaux',
    'Unis',
    'Universels',
    'Urbains',
    'Urgents',
    'Verticaux',
    'Verts',
    'Vibrants',
    'Vieux',
    'Vigoureux',
    'Violets',
    'Virtuels',
    'Vitaux',
    'Vivants',
    'Volants',
    'Vrais',
];
