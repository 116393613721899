export const pluralNounsMascFR: Array<string> = [
    'Abonnements',
    'Abris',
    'Accents',
    'Accidents',
    'Accords',
    'Achats',
    'Acides',
    'Aciers',
    'Actes',
    'Acteurs',
    'Administrateurs',
    'Adolescents',
    'Affrontements',
    'Agendas',
    'Agents',
    'Agriculteurs',
    'Aiguillages',
    'Albums',
    'Alignements',
    'Amateurs',
    'Ambassadeurs',
    'Amis',
    'Amusements',
    'Analystes',
    'Anges',
    'Angles',
    'Animaux',
    'Anneaux',
    'Anniversaires',
    'Annuaires',
    'Appareils',
    'Appartements',
    'Appels',
    'Arbitres',
    'Arbres',
    'Architectes',
    'Arcs',
    'Arguments',
    'Articles',
    'Artisanats',
    'Arts',
    'Ascenseurs',
    'Asiles',
    'Aspects',
    'Aspirateurs',
    'Assistants',
    'Ateliers',
    'Atouts',
    'Atterrissages',
    'Attributs',
    'Auditeurs',
    'Audits',
    'Auteurs',
    'Autobus',
    'Avantages',
    'Aveux',
    'Avis',
    'Avocats',
    'Bacs',
    'Baisers',
    'Balles',
    'Ballets',
    'Ballons',
    'Bancs',
    'Barils',
    'Barrages',
    'Bateaux',
    'Besoins',
    'Beurres',
    'Biens',
    'Bijoux',
    'Billets',
    'Biscuits',
    'Blocs',
    'Blogues',
    'Bonbons',
    'Bords',
    'Bouchons',
    'Boules',
    'Boutons',
    'Bovins',
    'Brevets',
    'Brins',
    'Brouillons',
    'Bruits',
    'Bugs',
    'Buissons',
    'Bulletins',
    'Bureaux',
    'Buts',
    'Cadeaux',
    'Cadres',
    'Cahiers',
    'Calculs',
    'Camions',
    'Campings',
    'Camps',
    'Campus',
    'Canaux',
    'Candidats',
    'Caoutchoucs',
    'Capitaines',
    'Carbones',
    'Carburants',
    'Cartes',
    'Cas',
    'Casino',
    'Casques',
    'Catalogues',
    'Cauchemars',
    'Cendres',
    'Centimes',
    'Centres',
    'Cercles',
    'Certificats',
    'Cerveaux',
    'Chalets',
    'Chaleurs',
    'Championnats',
    'Champions',
    'Champs',
    'Chandails',
    'Changements',
    'Chanteurs',
    'Chants',
    'Chapeaux',
    'Chapitres',
    'Charbons',
    'Charmes',
    'Chats',
    'Chauffages',
    'Chefs',
    'Chemins',
    'Chercheurs',
    'Chevaux',
    'Cheveux',
    'Chevreuils',
    'Chiens',
    'Chiffons',
    'Chips',
    'Chirurgiens',
    'Chocolats',
    'Chocs',
    'Choix',
    'Chroniqueurs',
    'Ciels',
    'Cieux',
    'Circuits',
    'Citoyens',
    'Citrons',
    'Civils',
    'Classements',
    'Classiques',
    'Claviers',
    'Clics',
    'Climats',
    'Clips',
    'Clous',
    'Clubs',
    'Cochons',
    'Cocktails',
    'Codes',
    'Coffres',
    'Coins',
    'Collectionneurs',
    'Combats',
    'Commandants',
    'Commanditaires',
    'Commentaires',
    'Commentateurs',
    'Commerces',
    'Commis',
    'Commutateurs',
    'Compagnons',
    'Complots',
    'Comportements',
    'Composants',
    'Compositeurs',
    'Compromis',
    'Comptes',
    'Compteurs',
    'Concepteurs',
    'Concerts',
    'Concours',
    'Concurrents',
    'Conducteurs',
    'Conflits',
    'Conjoints',
    'Consciences',
    'Conseillers',
    'Conseils',
    'Consensus',
    'Consentements',
    'Conservateurs',
    'Consommateurs',
    'Contacts',
    'Conteneurs',
    'Contextes',
    'Continents',
    'Contrastes',
    'Contrats',
    'Contributeurs',
    'Coordinateurs',
    'Copains',
    'Correctifs',
    'Correspondants',
    'Costumes',
    'Cotons',
    'Coudes',
    'Couloirs',
    'Couples',
    'Coups',
    'Courants',
    'Coureurs',
    'Courriels',
    'Courriers',
    'Cours',
    'Cousins',
    'Couteaux',
    'Crayons',
    'Cris',
    'Cristaux',
    'Crochets',
    'Croquis',
    'Cuirs',
    'Cuisiniers',
    'Cuivres',
    'Cultes',
    'Cycles',
    'Dangers',
    'Danseurs',
    'Derniers',
    'Descriptifs',
    'Dessins',
    'Destins',
    'Devis',
    'Devoirs',
    'Diables',
    'Diagnostics',
    'Diagrammes',
    'Dialogues',
    'Diamants',
    'Dictateurs',
    'Dictionnaires',
    'Dieux',
    'Diffuseurs',
    'Dilemmes',
    'Discours',
    'Dispositifs',
    'Disques',
    'Divertissements',
    'Divorces',
    'Documentaires',
    'Documents',
    'Doigts',
    'Dollars',
    'Domaines',
    'Dommages',
    'Dons',
    'Dossiers',
    'Doutes',
    'Drames',
    'Drapeaux',
    'Draps',
    'Droits',
    'Effectifs',
    'Effets',
    'Efforts',
    'Empires',
    'Emplacements',
    'Emplois',
    'Employeurs',
    'Emprisonnements',
    'Encouragements',
    'Endroits',
    'Enfers',
    'Engagements',
    'Engrenages',
    'Enjeux',
    'Ennemis',
    'Enregistrements',
    'Enseignements',
    'Ensembles',
    'Enterrements',
    'Entiers',
    'Entrepreneurs',
    'Entretiens',
    'Environnements',
    'Escaliers',
    'Espoirs',
    'Esprits',
    'Essais',
    'Estomacs',
    'Examens',
    'Exemples',
    'Exercices',
    'Exploits',
    'Explosifs',
    'Extraits',
    'Facteurs',
    'Faits',
    'Fantasmes',
    'Fardeaux',
    'Favoris',
    'Fers',
    'Festivals',
    'Feuilletons',
    'Feux',
    'Filets',
    'Films',
    'Fils',
    'Filtres',
    'Financements',
    'Fluides',
    'Flux',
    'Fonds',
    'Formateurs',
    'Formats',
    'Fossiles',
    'Fourgons',
    'Fours',
    'Fragments',
    'Fromages',
    'Fruits',
    'Fusils',
    'Gains',
    'Gallons',
    'Gants',
    'Garages',
    'Gardes',
    'Gars',
    'Gaz',
    'Genoux',
    'Genres',
    'Gens',
    'Gestes',
    'Globes',
    'Gouvernements',
    'Gouverneurs',
    'Griefs',
    'Groupes',
    'Guerriers',
    'Gymnases',
    'Habitats',
    'Haricots',
    'Hauts',
    'Historiens',
    'Hivers',
    'Hommages',
    'Honneurs',
    'Horaires',
    'Horizons',
    'Humains',
    'Humours',
    'Identifiants',
    'Immeubles',
    'Impacts',
    'Incidents',
    'Indicateurs',
    'Indices',
    'Insectes',
    'Insignes',
    'Inspecteurs',
    'Instincts',
    'Instituts',
    'Instructeurs',
    'Instruments',
    'Intervalles',
    'Invasions',
    'Inverses',
    'Investissements',
    'Investisseurs',
    'Jardins',
    'Jazz',
    'Jets',
    'Jeux',
    'Jouets',
    'Journaux',
    'Jours',
    'Jugements',
    'Jus',
    'Justificatifs',
    'Laboratoires',
    'Lacs',
    'Lancements',
    'Lasers',
    'Lavages',
    'Lecteurs',
    'Liens',
    'Lions',
    'Liquides',
    'Litres',
    'Lits',
    'Livres',
    'Locaux',
    'Logements',
    'Logiciels',
    'Logos',
    'Loisirs',
    'Loyers',
    'Magasins',
    'Magazines',
    'Mains',
    'Mandats',
    'Manifestants',
    'Manteaux',
    'Manuels',
    'Manuscrits',
    'Maquillages',
    'Marchands',
    'Mariages',
    'Marins',
    'Marqueurs',
    'Masques',
    'Matins',
    'Menaces',
    'Mensonges',
    'Menus',
    'Messages',
    'Messieurs',
    'Meubles',
    'Miracles',
    'Miroirs',
    'Missiles',
    'Mobiles',
    'Moines',
    'Mois',
    'Moments',
    'Mondes',
    'Monopoles',
    'Monstres',
    'Montres',
    'Monuments',
    'Morceaux',
    'Moteurs',
    'Motifs',
    'Mots',
    'Moulages',
    'Moulins',
    'Moutons',
    'Mouvements',
    'Moyens',
    'Murmures',
    'Murs',
    'Muscles',
    'Musiciens',
    'Mythes',
    'Navires',
    'Nids',
    'Niveaux',
    'Nombres',
    'Noms',
    'Nuages',
    'Nuits',
    'Objectifs',
    'Objets',
    'Observateurs',
    'Obstacles',
    'Officiers',
    'Oignons',
    'Oiseaux',
    'Oncles',
    'Orchestres',
    'Ordinateurs',
    'Ordres',
    'Organes',
    'Orteils',
    'Ouragans',
    'Ours',
    'Outils',
    'Outrages',
    'Ouvriers',
    'Paiements',
    'Pains',
    'Pairs',
    'Palais',
    'Palmiers',
    'Paniers',
    'Panneaux',
    'Papas',
    'Papiers',
    'Paquets',
    'Paragraphes',
    'Parapluies',
    'Parcours',
    'Parcs',
    'Parents',
    'Parieurs',
    'Paris',
    'Parkings',
    'Parlements',
    'Paroles',
    'Parrainages',
    'Partenariats',
    'Partisans',
    'Passagers',
    'Passages',
    'Passeports',
    'Pasteurs',
    'Patchs',
    'Patrimoines',
    'Patrons',
    'Pays',
    'Paysages',
    'Paysans',
    'Permis',
    'Personnages',
    'Personnels',
    'Peuples',
    'Pianos',
    'Pieds',
    'Pieux',
    'Pionniers',
    'Pipelines',
    'Placards',
    'Plafonds',
    'Plaidoyers',
    'Plaisirs',
    'Plannings',
    'Plans',
    'Plastiques',
    'Plis',
    'Pneus',
    'Poids',
    'Poignets',
    'Points',
    'Poissons',
    'Poivrons',
    'Policiers',
    'Politiciens',
    'Ponts',
    'Portefeuilles',
    'Portraits',
    'Potentiels',
    'Pouces',
    'Poulets',
    'Poumons',
    'Pourcentages',
    'Pourparlers',
    'Praticiens',
    'Princes',
    'Principes',
    'Prix',
    'Processeurs',
    'Processus',
    'Procureurs',
    'Productions',
    'Produits',
    'Professeurs',
    'Professions',
    'Profils',
    'Programmes',
    'Projecteurs',
    'Projets',
    'Protocoles',
    'Puits',
    'Punks',
    'Quartiers',
    'Questionnaires',
    'Quotas',
    'Radars',
    'Raids',
    'Rails',
    'Raisonnements',
    'Rangs',
    'Rappels',
    'Rapports',
    'Rassemblements',
    'Rats',
    'Rayonnements',
    'Rayons',
    'Recrutements',
    'Refus',
    'Regards',
    'Registres',
    'Regrets',
    'Rejets',
    'Reliefs',
    'Remplacements',
    'Rendements',
    'Renseignements',
    'Repas',
    'Reproductions',
    'Ressortissants',
    'Ressorts',
    'Restaurants',
    'Restes',
    'Retards',
    'Retour',
    'Revenus',
    'Rhumes',
    'Rideaux',
    'Rires',
    'Risques',
    'Rituels',
    'Rivages',
    'Robinets',
    'Robots',
    'Rochers',
    'Rois',
    'Romanciers',
    'Romans',
    'Rouleaux',
    'Routes',
    'Royaumes',
    'Ruisseaux',
    'Rythmes',
    'Sables',
    'Sacrifices',
    'Sacs',
    'Saints',
    'Salaires',
    'Sangs',
    'Satellites',
    'Sauts',
    'Sauvetages',
    'Saveurs',
    'Scandales',
    'Secrets',
    'Secteurs',
    'Segments',
    'Seigneurs',
    'Sels',
    'Sentiers',
    'Sentiments',
    'Serpents',
    'Serveurs',
    'Serviteurs',
    'Seuils',
    'Signaux',
    'Silences',
    'Singes',
    'Sites',
    'Skis',
    'Slogans',
    'Socles',
    'Soins',
    'Soldats',
    'Sols',
    'Sommets',
    'Sondages',
    'Sons',
    'Sorts',
    'Soucis',
    'Soupirs',
    'Sourires',
    'Souris',
    'Soutiens',
    'Souvenirs',
    'Spams',
    'Spectacles',
    'Spectres',
    'Sports',
    'Stades',
    'Stands',
    'Statues',
    'Stockages',
    'Stylos',
    'Successeurs',
    'Sucres',
    'Suites',
    'Sujets',
    'Superviseurs',
    'Supports',
    'Surplus',
    'Symboles',
    'Syndicats',
    'Syndromes',
    'Tabacs',
    'Tableaux',
    'Talents',
    'Talons',
    'Tambours',
    'Tampons',
    'Tapis',
    'Tarifs',
    'Taux',
    'Taxis',
    'Temples',
    'Tennis',
    'Terminaux',
    'Terrains',
    'Territoires',
    'Testaments',
    'Tests',
    'Textes',
    'Thermes',
    'Timbres',
    'Tirs',
    'Tissus',
    'Titres',
    'Toits',
    'Totaux',
    'Tournois',
    'Trains',
    'Traitements',
    'Traits',
    'Transferts',
    'Transparents',
    'Transports',
    'Traumatismes',
    'Travaux',
    'Tribunaux',
    'Triomphes',
    'Troubles',
    'Trous',
    'Trucs',
    'Tubes',
    'Tunnels',
    'Tuyaux',
    'Types',
    'Uniformes',
    'Univers',
    'Usages',
    'Utilitaires',
    'Ventilateurs',
    'Vents',
    'Verdicts',
    'Versets',
    'Versions',
    'Vertus',
    'Vices',
    'Villages',
    'Vins',
    'Virus',
    'Visages',
    'Visites',
    'Visiteurs',
    'Voisins',
    'Voleurs',
    'Vols',
    'Volumes',
    'Votes',
    'Voyages',
    'Voyageurs',
    'Yeux',
];
