export const adjFemFR: Array<string> = [
    'Absentes',
    'Absolues',
    'Accueilantes',
    'Actives',
    'Adjacentes',
    'Administratives',
    'Affreuses',
    'Alternatives',
    'Amateures',
    'Ambitieuses',
    'Amicales',
    'Amusantes',
    'Anciennes',
    'Angoissantes',
    'Annuelles',
    'Anxieuses',
    'Apparentes',
    'Artificielles',
    'Astucieuses',
    'Attendues',
    'Attirantes',
    'Attractives',
    'Authentiques',
    'Automatiques',
    'Belles',
    'Bienvenues',
    'Bizarres',
    'Blanches',
    'Bleus',
    'Blondes',
    'Bonnes',
    'Breves',
    'Brillantes',
    'Brunes',
    'Brutes',
    'Bruyantes',
    'Centrales',
    'Certaines',
    'Chaleureuses',
    'Chanceuses',
    'Charmantes',
    'Chaudes',
    'Cheres',
    'Chirurgicales',
    'Choquantes',
    'Civiles',
    'Cognitives',
    'Collantes',
    'Collectives',
    'Commerciales',
    'Communes',
    'Completes',
    'Confortables',
    'Confuses',
    'Conscientes',
    'Conservatrices',
    'Constantes',
    'Constitutionnelles',
    'Contemporaines',
    'Continues',
    'Convaincantes',
    'Convaincues',
    'Conventionnelles',
    'Correctes',
    'Correspondantes',
    'Courageuses',
    'Courantes',
    'Couvertes',
    'Creuses',
    'Cruciales',
    'Cruelles',
    'Culturelles',
    'Curieuses',
    'Dangereuses',
    'Dernieres',
    'Destructrices',
    'Directes',
    'Disparues',
    'Distinctives',
    'Distinctes',
    'Divertissantes',
    'Divines',
    'Dominantes',
    'Douloureuses',
    'Douces',
    'Droites',
    'Dures',
    'Effrayantes',
    'Encourageantes',
    'Endommageantes',
    'Endormies',
    'Engageantes',
    'Ennuyeuses',
    'Entieres',
    'Exactes',
    'Excellentes',
    'Exceptionnelles',
    'Excessives',
    'Exclusives',
    'Expertes',
    'Explosives',
    'Extensives',
    'Fabuleuses',
    'Familieres',
    'Fascinantes',
    'Fatales',
    'Fausses',
    'Favories',
    'Fieres',
    'Financieres',
    'Finales',
    'Fonctionnelles',
    'Fondamentales',
    'Formelles',
    'Fortes',
    'Folles',
    'Fraiches',
    'Frappantes',
    'Froides',
    'Frustrantes',
    'Furieuses',
    'Futures',
    'Globales',
    'Glorieuses',
    'Grandes',
    'Grises',
    'Grossieres',
    'Habituelles',
    'Hautes',
    'Heureuses',
    'Hilarantes',
    'Honteuses',
    'Idiotes',
    'Imminentes',
    'Impaires',
    'Impatientes',
    'Importantes',
    'Impressionnantes',
    'Inattendues',
    'Inconnues',
    'Incorrectes',
    'Indirectes',
    'Individuelles',
    'Industrielles',
    'Influentes',
    'Informelles',
    'Inhabituelles',
    'Initiales',
    'Innocentes',
    'Innovantes',
    'Institutionnelles',
    'Instrumentales',
    'Insuffisantes',
    'Intactes',
    'Intellectuelles',
    'Intelligentes',
    'Intensives',
    'Interactives',
    'Internationales',
    'Intrigantes',
    'Jolies',
    'Joyeuses',
    'Lentes',
    'Locales',
    'Lointaines',
    'Lourdes',
    'Luxueuses',
    'Majeures',
    'Malheureuses',
    'Marginales',
    'Marines',
    'Martiales',
    'Massives',
    'Mauvaises',
    'Meilleures',
    'Mensuelles',
    'Merveilleuses',
    'Mignones',
    'Minimales',
    'Morales',
    'Municipales',
    'Musicales',
    'Mutuelles',
    'Nationales',
    'Naturelles',
    'Navales',
    'Nerveuses',
    'Nocives',
    'Nombreuses',
    'Normales',
    'Nouvelles',
    'Objectives',
    'Occasionnelles',
    'Occidentales',
    'Offensives',
    'Officielles',
    'Opportunes',
    'Originales',
    'Ouvertes',
    'Parentales',
    'Paresseuses',
    'Parfaites',
    'Particulieres',
    'Partielles',
    'Passives',
    'Passionnantes',
    'Patientes',
    'Perdues',
    'Permanentes',
    'Peresistantes',
    'Peresonnelles',
    'Pertinentes',
    'Petites',
    'Pliantes',
    'Pointues',
    'Polies',
    'Positives',
    'Premieres',
    'Prestigieuses',
    'Principales',
    'Productives',
    'Professionnelles',
    'Profondes',
    'Progressives',
    'Prometteuses',
    'Protectrices',
    'Provinciales',
    'Prudentes',
    'Puissantes',
    'Pures',
    'Radicales',
    'Rationnelles',
    'Ravies',
    'Reconnaissantes',
    'Relatives',
    'Relaxantes',
    'Religieuses',
    'Respectives',
    'Rivales',
    'Royales',
    'Rugueuses',
    'Rurales',
    'Saintes',
    'Satisfaites',
    'Secretes',
    'Seules',
    'Significatives',
    'Silencieuses',
    'Sociales',
    'Spirituelles',
    'Sportives',
    'Strictes',
    'Substantielles',
    'Subtiles',
    'Successives',
    'Suffisantes',
    'Surprenantes',
    'Surprises',
    'Talentueuses',
    'Terrifiantes',
    'Traditionnelles',
    'Tranchantes',
    'Transparentes',
    'Tribales',
    'Trompeuses',
    'Tropicales',
    'Unies',
    'Universelles',
    'Urbaines',
    'Urgentes',
    'Verticales',
    'Vertes',
    'Vibrantes',
    'Vieilles',
    'Vigoureuses',
    'Violettes',
    'Virtuelles',
    'Vitales',
    'Vivantes',
    'Volantes',
    'Vraies',
];
