export const adjNeutralFR: Array<string> = [
    'Abordables',
    'Absurdes',
    'Acceptables',
    'Accessibles',
    'Acides',
    'Adultes',
    'Agricoles',
    'Anonymes',
    'Arbitraires',
    'Artistiques',
    'Aveugles',
    'Biologiques',
    'Calmes',
    'Capables',
    'Capitalistes',
    'Catastrophiques',
    'Chimiques',
    'Chroniques',
    'Civiques',
    'Classiques',
    'Communistes',
    'Complexes',
    'Contraires',
    'Coupables',
    'Critiques',
    'Cultes',
    'Cyniques',
    'Denses',
    'Difficiles',
    'Dignes',
    'Diplomatiques',
    'Disponibles',
    'Diverses',
    'Durables',
    'Dynamiques',
    'Efficaces',
    'Empiriques',
    'Enthousiastes',
    'Exotiques',
    'Explicites',
    'Externes',
    'Extraordinaires',
    'Extraterrestres',
    'Faciles',
    'Faibles',
    'Fantaisistes',
    'Fantastiques',
    'Favorables',
    'Fiables',
    'Fragiles',
    'Graphiques',
    'Hebdomadaires',
    'Historiques',
    'Horribles',
    'Humanitaires',
    'Humbles',
    'Humoristiques',
    'Identiques',
    'Imaginaires',
    'Immenses',
    'Impossibles',
    'Improbables',
    'Inacceptables',
    'Incapables',
    'Inconfortables',
    'Incroyables',
    'Indispensables',
    'Injustes',
    'Innombrables',
    'Intenses',
    'Internes',
    'Intimes',
    'Inutiles',
    'Invisibles',
    'Ironiques',
    'Jaunes',
    'Juniors',
    'Larges',
    'Liquides',
    'Lisses',
    'Logiques',
    'Longues',
    'Magiques',
    'Magnifiques',
    'Matures',
    'Minces',
    'Minuscules',
    'Mixtes',
    'Mobiles',
    'Modernes',
    'Modestes',
    'Neutres',
    'Nobles',
    'Notables',
    'Obligatoires',
    'Optimistes',
    'Optiques',
    'Oranges',
    'Ordinaires',
    'Pauvres',
    'Philosophiques',
    'Plastiques',
    'Politiques',
    'Populaires',
    'Pratiques',
    'Primaires',
    'Probables',
    'Proches',
    'Psychologiques',
    'Publiques',
    'Raisonnables',
    'Rapides',
    'Rares',
    'Redevables',
    'Remarquables',
    'Rentables',
    'Responsables',
    'Riches',
    'Ridicules',
    'Rigides',
    'Robustes',
    'Romantiques',
    'Roses',
    'Rouges',
    'Rudes',
    'Sages',
    'Sales',
    'Sauvages',
    'Sceptiques',
    'Scientifiques',
    'Secondaires',
    'Sensibles',
    'Simples',
    'Socialistes',
    'Solaires',
    'Solides',
    'Sombres',
    'Souhaitables',
    'Souples',
    'Spectaculaires',
    'Stables',
    'Stupides',
    'Superbes',
    'Symboliques',
    'Sympathiques',
    'Tactiques',
    'Techniques',
    'Technologiques',
    'Temporaires',
    'Tendres',
    'Ternes',
    'Terribles',
    'Timides',
    'Toxiques',
    'Typiques',
    'Ultimes',
    'Uniques',
    'Utiles',
    'Valides',
    'Variables',
    'Vastes',
    'Viables',
    'Vides',
    'Visibles',
    'Volontaires',
];
